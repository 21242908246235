<script setup>

</script>

<template>
  <input class="bg-white/10 px-2.5 py-3 rounded-md border-2 border-white/10 placeholder-white/30 focus:outline-0" type="number" placeholder="0.00">
</template>

<style scoped>

</style>